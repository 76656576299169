
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap');

/* Project Page Header */
.project-header-container {
  background: rgb(255, 251, 228);
}

.project-header-grid-container {
  /* background: rgb(255, 255, 255); */
  /* background-image: url('/media/error/bg-header1.png'); */
  background-image: url('/media/error/bg-header1.png');
  padding: 14px !important;
  border: 1px rgb(196, 200, 255, 0.5) solid;
  border-radius: 6px;
}

.project-header-name {
  margin-bottom: 10px;
  /* border-bottom: solid rgb(233, 233, 233) 1px; */
}

.project-header-value-lg {  
  font-family: 'Open Sans', sans-serif !Important;
  font-size: 20px !important;
  font-weight: 900 !important;
  color: rgb(71, 83, 151, 0.85);
  padding-bottom: 10px;
}

.project-header-label-sm {  
  font-family: 'Open Sans', sans-serif !Important;
  font-size: 11px !important;
  font-weight: 700 !important;
  color: rgb(71, 83, 151, 0.50);
}

.project-header-value-sm {  
  font-family: 'Open Sans', sans-serif !Important;
  font-size: 13px !important;
  font-weight: 700 !important;
  color: rgb(56, 66, 121);
}

/* Tables for Summary forms */
.summary-div {
  background-image: linear-gradient( white, #f2f2f2, white, #fdfdfd);
  padding: 20px 0px;
  border-radius: 5px;
}

.summary-table {
  background-image: linear-gradient( #fefefe, #f2f2f2, white, #fdfdfd);
  padding: 5px;
  border-radius: 5px;
}

.summary-table-short {
  background-image: linear-gradient(#f4f4f4, white, #fdfdfd);
  padding: 5px;
}

.summary-table-mid {
  background-image: linear-gradient(#f7f7f7, #f2f2f2, white, #fdfdfd);
  padding: 5px;
}

/* div for manpower container */
.manpower-div {
  background: rgba(255, 255, 255, 0.85);
  padding: 20px 0px;
}

/* .table th, .table td {
  padding-left: 10px !Important;
} */

.nav-pills, .nav-tabs {
  margin: 0 0 5px 0;
}

.summary-header {
  font-weight: 800 !important;
  background: rgba(255, 255, 255, 0.50);
  border-bottom: 2px solid #bbb;
}

.summary-header-actual {
  font-weight: 800 !important;
  background: rgba(200, 202, 211, 0.50);
  border-bottom: 2px solid #bbb;
}

.summary-header-quoted {
  font-weight: 800 !important;
  background: rgba(209, 215, 241, 0.50);
  border-bottom: 2px solid #bbb;
}

.summary-cell-actual {  
  background: rgba(200, 202, 211);
  /* background-color: #c8cad3 !Important; */
}

.summary-cell-quoted {
  background: rgba(209, 215, 241);
  /* background-color: #d1d7f1 !Important; */
}

.summary-inner {
  display: table;
  margin: 0 auto;
}

.summary-innercfe {
  display: table;
  margin: 0 auto;
}

.summary-info-cell {  
  height: 2.75em;
  line-height: 2.75em;
  font-family: 'Open Sans', sans-serif !Important;
  font-size: 13px;
}

.expense-header-container {
  background-image: linear-gradient(white, #f4f4f4, white, #fdfdfd);
  padding: 10px 0px 5px 0px;
  border-radius: 5px;
}

.grid-inputgroup-lg {
  width: 155px !important;
}

.grid-inputgroup {
  width: 125px !important;
}

.grid-select {
  border-color: #aaaaaa !Important;
  background-color: #ffe9bc !Important;
}

.input-group-text {
  border-color: #aaaaaa !Important;
}

.grid-formcontrol {
  text-align: right;
  border-color: #aaaaaa !Important;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  background-image: none !Important;
  padding: 0.65rem 1rem !Important;
  border-color: red !Important;
}

.excel-inputDate {
  background-color: #ffe9bc !Important;
  padding: 0.65rem 1rem;
}

.summary-dense {
  margin-top: 0 !Important;
  margin-bottom: 0 !Important;
}

.summary-date {
  width: 131px !Important;
}

.input-group-prepend {
  margin-right: -1px;
  width: 34px;
}

  /* styles for MUI form controls */
  
.MuiIconButton-root {
  padding: 0px !Important;
}

.textfield-error {
  background-color: #ffbebe !Important;
  border-radius: 4px;
}

.textfield-edit {
  background-color: #ffe9bc !Important;
  border-radius: 4px;
}

.textfield-edit-negative {
  background-color: #ffe9bc !Important;
  border-radius: 4px;
}

.textfield {
  background-color: #ffffff !Important;
  border-radius: 4px;
}

.textfield-edit-100 {
  background-color: #ffe9bc !Important;
  border-radius: 4px;
  width: 100% !Important;
}

.textfield-disabled-100 {
  border-radius: 4px;
  width: 100% !Important;
}

.input-money-edit {
    text-align: right;
    width: 76px !Important;
    font-family: 'Open Sans', sans-serif !Important;
    font-size: 13px;
}

.input-money-edit-negative {
  text-align: right;
  width: 76px !Important;
  font-family: 'Open Sans', sans-serif !Important;
  font-size: 13px;
  color: red !Important;
}

.input-number-edit {
  text-align: right;
  width: 90px !Important;
  font-family: 'Open Sans', sans-serif !Important;
  font-size: 13px;
}

.input-number-edit-negative {
  text-align: right;
  width: 90px !Important;
  font-family: 'Open Sans', sans-serif !Important;
  font-size: 13px;
  color: red !Important;
}

.input-pc-edit {
  text-align: right;
  width: 74px !Important;
  font-family: 'Open Sans', sans-serif !Important;
  font-size: 13px;
}

.input-pc-edit-negative {
  text-align: right;
  width: 74px !Important;
  font-family: 'Open Sans', sans-serif !Important;
  font-size: 13px;
  color: red !Important;
}

.input-text-edit-100 {
  text-align: left;
  font-family: 'Open Sans', sans-serif !Important;
  font-size: 13px;
}

.input-text-edit-right {
  text-align: right;
  font-family: 'Open Sans', sans-serif !Important;
  font-size: 13px;
}
.select-edit {
  min-width: 116px;
  background-color: #ffe9bc;
  /* full-width: true;  */
  display: 'flex';
  /* wrap: 'nowrap' */
}

.text-number {
  font-family: 'Open Sans', sans-serif !Important;
  text-align: right;
}

.text-number-left {
  font-family: 'Open Sans', sans-serif !Important;
}

.select {
  min-width: 116px;
  display: 'flex';
}

.label-disabled {
  color: #b9b9b9;
}

.autoNumeric-negative {
  color: red !important; 
}

.help-popover-title-label {
  font-size: 15px !important;
  font-weight: 900px !important;
  padding-left: 8px;;
}

.field-error {
  font-size: 11px !important;
  font-weight: 900px !important;
  color: red;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
/* Flexgrid */

.wj-flexgrid {
  /* height: 700px; */
  margin: 8px 0;
}

.wj-colheaders {
  background-color:#e1e1e1 !important;
  background: #e1e1e1 !important;
}

.group-row {
  background-color:#e1e1e1;
  background: #e1e1e1;
}

.cell-monospace-right {
  font-family: 'Open Sans', sans-serif !important;
  text-align: right !important;
}

.cell-monospace-center {
  font-family: 'Open Sans', sans-serif !important;
  text-align: center !important;
}

.cell-input-monospace-right {
  font-family: 'Open Sans', sans-serif !important;
  text-align: right !important;
  background-color: #ffe9bc !important;
  background: #ffe9bc;
}

.quoted-cell {
  background-color: #d1d7f1;
}

.input-cell {
  font-family: 'Open Sans', sans-serif !important;
  background-color: #ffe9bc;
  background: #ffe9bc;
}

.actual-cell {
  font-family: 'Open Sans', sans-serif !important;
  background-color: rgb(226, 228, 235);
  background: rgba(226, 228, 235);
}

.quoted-cell {
  font-family: 'Open Sans', sans-serif !important;
  background-color: rgba(209, 215, 241);
  background: rgba(209, 215, 241);
}

/* orange total rows in various summary grids */
.subtotal-row-highlight {
  background-color: rgb(253, 191, 98) !important;
  font-weight: 600 !important;
}

/* FlexGrid */

/* Client Summary */

#client-summary-grid.wj-flexgrid .wj-cell {
  padding-top: 4px;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 3px;
  font-size: 11px;
  font-family: 'Open Sans', sans-serif !important;
}

#client-summary-grid.wj-flexgrid .wj-header {
  text-align: center !important;
  font-family: Poppins, Helvetica, sans-serif !important;
  font-size: 12px;
}

.client-summary-sectionheader {
  background-color: rgb(253, 191, 98) !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  text-align: left !important;
}

.client-summary-sectionsubheader {
  font-weight: 700 !important;
  text-decoration: underline;
  text-align: left !important;
}

.client-summary-sectionsubtotal {
  font-weight: 700 !important;
  font-size: 11px !important;
  text-align: right;
}

.client-summary-sectiontotal {
  font-weight: 700 !important;
  text-align: right;
}

.client-summary-grandtotal {
  background-color: rgb(253, 191, 98) !important;
  font-weight: 700 !important;
  text-align: right;
}

/* Expense Template items */

#expense-template-grid.wj-flexgrid .wj-cell {
  padding: 4px;
  font-size: 12px; 
  font-family: 'Open Sans', sans-serif !important;
}

.wj-listbox{
  max-height: 300px !important;
  height:240px;
  font-size: 11px;
  font-family: 'Open Sans', sans-serif !important;
  }

#expense-template-copy-grid.wj-flexgrid .wj-cell {
  padding: 4px;
  font-size: 12px; 
  font-family: 'Open Sans', sans-serif !important;
}

#expense-template-copy-grid.wj-flexgrid .wj-cells .wj-cell.wj-state-multi-selected {
  background: #d1dfe5 !important;
  color: #000 !important;
}

#expense-template-copy-grid.wj-flexgrid .wj-cell input.wj-column-selector.wj-column-selector-group {
  transform: scale(1);
  margin-left: 2px;
  margin-right: 6px;
}

#manpower-cost-grid.wj-flexgrid .wj-cell {
  padding: 4px;
  font-size: 12px; 
  font-family: 'Open Sans', sans-serif !important;
}

#manpower-cost-grid.wj-flexgrid .wj-header {
  padding: 4px;
  padding-left: 5px;
  font-size: 12px; 
  font-family: 'Poppins' !important;
}

#manpower-cost-grid.wj-flexgrid .wj-group {
  padding: 4px;
  font-size: 12px; 
  font-family: 'Poppins' !important;
}

#manpower-grid.wj-flexgrid .wj-cell {
  padding: 4px;
  font-size: 12px; 
  font-family: 'Open Sans', sans-serif !important;
}

#manpower-grid.wj-flexgrid .wj-header {
  padding: 4px;
  padding-left: 5px;
  font-size: 12px; 
  font-family: 'Poppins' !important;
}


.precon-cell {
  background-color: #a9d08e;
}

.construction-cell {
  background-color: #9bc2e6;
}

.closeout-cell {
  background-color: #ffe28c;
}

.Precon {
  background-color: #a9d08e;
}

.Construction {
  background-color: #9bc2e6;
}

.Close-out {
  background-color: #ffe28c;
}

.wj-header {
  text-align: right !important;
  background-color: #12e057;
  }

.header-left {
  text-align: left !important;
}

.header-right {
  text-align: right !important;
}

.header-center {
  text-align: center !important;
}

#expenses-grid.wj-flexgrid .wj-header {
  text-align: center !important;
  font-family: Poppins, Helvetica, sans-serif !important;
}

#expenses-grid .wj-glyph-up {
  display: none;
}

#expenses-grid .wj-sort-index {
  display: none;
}

#precon-grid .wj-glyph-up {
  display: none;
}

#manpower-cost-grid .wj-glyph-up {
  display: none;
}

#manpower-grid .wj-glyph-up {
  display: none;
}

/* Page Titles with Add button on the right */
.add-button-title {
  width: 100;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.add-button-manpower {
  width: 100;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 5px;
  margin-top: -97px
}

.add-button {
  width: 100;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px;
  margin-bottom: 5px;
}

.quotedpc-precon {
  width: 100;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;
  margin-bottom: 5px;
}

.bidtstatus-label {
  width: 180px;
}

.bidtstatus-data {
  width: 500px;
  /* padding: 1rem !Important; */
}

.version-col-description {
  width: 180px;
}

.version-col-createdon {
  width: 180px;
}

.version-col-createdby {
  width: 180px;
}

.version-col-actions {
  width: 180px;
}

.bid-progress {
  margin-top: 6px;
}

.dashboardbids-progress {
  margin-top: 4px;
}

.progress {
  height: 1.5rem !Important;
  width: 200px;
}

/* Modals */


.edit-md-dialog-modal {
  max-width: 800px !important;
  height: 700px !important;
}

.edit-md-modal {
  background-color: rgb(245, 245, 245);
}

.edit-mdlg-dialog-modal {
  max-width: 1100px !important;
  height: 700px !important;
}

.edit-lg-dialog-modal {
  max-width: 1100px !important;
  height: 700px !important;
}

.edit-lg-modal {
  background-color: rgb(245, 245, 245);
}

.edit-sm-dialog-modal {
  max-width: 600px !important;
  /* height: 700px !important; */
}

.edit-sm-modal {
  background-color: rgb(245, 245, 245);
}

.edit-sm-modal-second-modal {
  background-color: rgb(245, 245, 245);
  border: 1px rgb(175, 175, 175) solid;
  border-radius: 4px;
}

.addresource-modal {
  background-color: #efefef;
}

.changetasks-modal {
  max-width: 950px !important;
}

.addversion-modal {
  max-width: 400px !important;
  background-color: #efefef;
}

.edituser-modal {
  max-width: 750px !important;
  background-color: rgb(245, 245, 245);
}

.addBidPermissions-modal {
  max-width: 500px !important;
  background-color: rgb(245, 245, 245);
}

.small-summary-table-th {
  background-color: rgb(242, 242, 242);
}

.view-fullscreen-dialog-modal {
  max-width: 98% !important;
  height: 95% !important;
  background-color: rgb(245, 245, 245);
}

.cell-link {
  font-weight: 600;
  text-decoration: underline;
  color: rgb(47, 47, 146);
  cursor: pointer;
}

/* bootstrap override for inputs in list header */

.input-group .input-group-text {
  border-color: #e2e5ec !important;
}

.checklist-item {  
  width: 100%;
  border: 0px solid rgb(214, 228, 255);
  margin-bottom: 8px;
  background-color: rgb(240, 251, 255);
  padding: 0px;
  border-radius: 4px;
}

.tag-box {
  background-color: #f3f3f3 !important;
  border-radius: 4px;
  padding: 10px; 
  max-width: 450px; 
  border: 1px solid rgb(194, 214, 255);
}

.tag-select-new {
  width: 130px;
  height: 35px !important;
  margin-bottom: 5px;
  background-color: rgb(240, 251, 255) !important;
  border: 1px solid silver !important;
}

.tag-select-new-blank-input {
  width: 180px;
  height: 35px !important;
  margin-bottom: 5px;
  background-color: 'white';
  border: 1px solid silver !important;
}

.tag-input-prepend {
  width: 120px;
  height: 35px;
  margin-bottom: 5px;
}

.tag-input-prepend-text {
  width: 130px;
}

.tag-input {
  width: 180px;
  height: 35px !important;
  margin-bottom: 5px;
  background-color: 'white';
}

.tag-input-append {
  height: 35px;
  margin-bottom: 5px;
  cursor: pointer;
}

.tag-search-container {
  padding: 10px;
  width: 100%;
}

.tag-search-input-container {
  width: 450px;
}

.tag-search-item {
  font-size: 12px;
  border: 1px solid silver;
  background-color: rgb(240, 251, 255);
  border-radius: 4px;
  margin: 10px 10px 10px 0;
  display: inline-block;
  height: 35px;
  margin-bottom: 5px;
  padding: 7px 10px 0px 10px;
}

.tag-search-item-delete {
  margin-left: 8px;
}


.help-aside {
  background-color: rgb(255, 245, 188);
  border: 1px solid #ffe564;
  border-left: 4px solid #ffe564;
  padding: 1.2rem 1.2rem 0.5rem 1.2rem;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}

.site-label {
  font-size: 12px;
  font-weight: 900;
}

.site-data-container {
  margin-bottom: 1rem;
}

.site-data-lg {
  color: #474747;
  font-size: 20px;
  font-weight: 300;
}

.site-data-md {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.site-item {
  color: black;
  text-align: center;
  font-size: 10px;
  font-weight: 900;
  padding-top: 1px;
}

.site-items-abbr {
  color: black;
  margin-right: 6px;
  margin-bottom: 5px;
  border: green 1px solid;
  background-color: #eaffdb;
  min-width: 20px;
  text-align: center;
  font-size: 10px;
  font-weight: 900;
}

.site-coverPhoto {
  max-width: 100%;
}


@media screen and (min-width: 799px) {  
  .site-coverPhoto {
    max-width: 450px;
  }
}


.places-sugggestions {  
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.733); 
  padding-left: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.pac-container {
  background-color: rgba(255, 255, 255, 0.850);
  position: absolute!important;
  z-index: 1000;
  border-radius: 2px;
  border-top: 1px solid #d9d9d9;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden
}

.pac-item {
  cursor: pointer;
  padding: 2px 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 30px;
  text-align: left;
  border-top: 1px solid #e6e6e6;
  font-size: 12px;
}

.pac-main {
  font-weight: 600;
}

.pac-secondary {
  font-weight: 400;
}
