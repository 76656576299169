
.map {
  width: 100%;
}

.map-h2 {
    text-transform: uppercase;
    font-size: 1rem;
    padding: 0;
    text-align: center;
  }
  
  .google-map {
    width: 100%;
    height: 60vh;
  }
  
  .pin {
    display: flex;
    align-items: center;
    width: 180px;
    color: var(--main-blue);
  }
  
  .pin-icon {
    font-size: 4rem;
  }
  
  .pin-text {
    font-size: 1.3em;
  }
  
  @media screen and (min-width: 799px) {
    .map {
      width: 400px;
    }
    
    .google-map {
      width: 100%;
      height: 80vh;
    }
  
    .map-h2 {
      padding: 0;
    }
  
    .pin {
      width: 15vw;
    }
  
    .pin-icon {
      font-size: 10vw;
    }
  }